<template>
  <!-- 开发人员：王雪茹 -->
  <div>
    <!-- <Wang-skeleton v-if="isSkeleton"
                       type="custom"
                       :childrenOption="childrenOptions"
                       style="background: #fff;margin-top:10px;padding:10px 20px" /> -->
    <div class="roi_box">
      <el-card class="roi_left">
        <ul style="height: 100%; border-right: 1px solid #ccc; width: 40%">
          <!-- 市场活动成员 -->
          <li class="font_tit">{{ $t("label.marketactivitymember") }}</li>
          <li class="font_s">{{ priData.memberNumber }}</li>
        </ul>
        <div style="width: 60%; display: flex">
          <ul style="margin: 0 33px">
            <!-- 市场潜在客户 -->
            <!-- {{ $t("label.brief.lead") }} -->
            <li class="font_tit">{{ $t("market.potential.customers") }}</li>
            <li class="font_s">{{ priData.leadMemberNumber }}</li>
          </ul>
          <ul>
            <!-- 市场联系人 -->
            <!-- {{ $t("label_tabpage_contactsz") }} -->
            <li class="font_tit">{{ $t("market.contacts") }}</li>
            <li class="font_s">{{ priData.contactMemberNumber }}</li>
          </ul>
        </div>
      </el-card>
      <el-card class="roi_right">
        <!-- <ul class="lef" style="width:7%"> 
        <li class="font_tit">{{$t('label.currency')}}</li>
        <li class="font_s">{{priData.Currency}}</li>
      </ul>    style="margin-left:20px;"-->
        <ul class="lef">
          <!-- 实际成本 -->
          <li class="font_tit">{{ $t("label.campagin.roi.sjcb") }}</li>
          <li class="font_s">
            {{ priData.Currency }}&nbsp;{{priData.sjcb?
              (priData.sjcb === "-"
                ? priData.sjcb
                : parseInt(priData.sjcb)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")):0
            }}
          </li>
        </ul>
        <div class="cont">
          <ul style="margin: 0 33px">
            <!-- 收入金额 -->
            <li class="font_tit">{{ $t("vue_label_campaign_amount") }}</li>
            <li class="font_s">
              {{ priData.Currency }}&nbsp;{{priData.srjine?
                (parseInt(priData.srjine)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")):0
              }}
              <span style="font-size: 12px">
                <!-- （来源{{priData.dsrnum}}个赢单的业务机会） -->
                （{{
                  $t("vue_label_campaign_fromclosedopprotunities", {
                    sjcb: priData.srnum,
                  })
                }}）
              </span>
            </li>
          </ul>
          <!--          <ul>-->
          <!--            <li-->
          <!--              class="font_tit"-->
          <!--              style="color: #979797; line-height: 50px; font-size: 24px"-->
          <!--            >-->
          <!--              +-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--          <ul style="margin: 0 33px">-->
          <!--            &lt;!&ndash; 待收入金额 &ndash;&gt;-->
          <!--            <li class="font_tit">{{ $t("vue_label_campaign_earned") }}</li>-->
          <!--            <li class="font_s">-->
          <!--              {{ priData.Currency }}&nbsp;{{-->
          <!--                parseInt(priData.dsrjine)-->
          <!--                  .toFixed(2)-->
          <!--                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")-->
          <!--              }}-->
          <!--              <span style="font-size: 12px">-->
          <!--                &lt;!&ndash; （来源{{priData.srnum}}个进行的业务机会） &ndash;&gt;-->
          <!--                （{{-->
          <!--                  $t("vue_label_campaign_fromopprotunities", {-->
          <!--                    dsrjine: priData.dsrnum,-->
          <!--                  })-->
          <!--                }}）-->
          <!--              </span>-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
        <ul class="rig">
          <!-- 市场活动ROI -->
          <li class="font_tit">{{ $t("label.campagin.roi.query.roi") }}</li>
          <li class="font_s">{{ priData.roi }}</li>
        </ul>
      </el-card>
    </div>
  </div>
</template>
<script>
import { CampaignROI } from "./api";

export default {
  name: "roicard",
  data() {
    return {
      priData: {},
      // childrenOptions: [
      //   // 列表
      //   {
      //     type: "list",
      //     options: {
      //       row: 2,
      //       column: 6,
      //       width: "150px",
      //       height: "20px",
      //       backgroundColor: "#f3f2f2",
      //       justifyContent: "center",
      //       active: false,
      //       itemRowMargin: "8px 8px 8px 26px",
      //       firstWidth: "150px",
      //       lastWidth: "150px",
      //       alignItems: "center",
      //     },
      //   },
      // ],
      // isSkeleton: true,
    };
  },
  methods: {
    // 父组件中通过ref调用
    init() {
      CampaignROI({ id: this.$route.params.id }).then((res) => {
        this.priData = res.data;
        // this.isSkeleton = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.roi_box {
  display: flex;
  margin-top: 10px;
  width: 100%;
  .roi_left {
    width: 40%;
    min-height: 75px;
    margin-right: 10px;
    ::v-deep .el-card__body {
      display: flex;
      padding: 15px 20px;
      box-sizing: border-box;
      ul {
        margin-bottom: 0;
      }
    }
  }
  .roi_right {
    width: 60%;
    min-height: 75px;
    ::v-deep .el-card__body {
      display: flex;
      padding: 15px 20px;
      box-sizing: border-box;
      ul {
        margin-bottom: 0;
      }
      .rig {
        height: 100%;
        width: 25%;
        border-left: 1px solid #ccc;
        padding-left: 30px;
      }
      .lef {
        width: 25%;
        height: 100%;
        border-right: 1px solid #ccc;
      }
      .cont {
        width: 50%;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
  .font_s {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  .font_tit {
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    line-height: 18px;
  }
}
</style>