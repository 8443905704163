import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve,) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 获取CCChat小组列表
export function queryUserGroups(data) {
    return axios.post("/ccchat/usergroup/queryUserGroups", data);
}
// 获取CCChat追随人员列表
export function queryFollows(data) {
    return axios.post("/ccchat/person/queryFollows", data);
}
//获取相关列表对象信息
export function GetRelationList(data) {
    return axios.post('/objectdetail/getRelationList', data)
}
//获取市场活动卡片
export function CampaignROI(data) {
    return axios.post('/campaign/campaignROI', data)
}
//文件-附件添加
export function Bind(data) {
    return axios.post('/file/bind', data)
}
//联系人角色 删除
export function deleteContact(data) {
    return axios.post('/contactRole/delete', data)
}
//获取对象列表
export function getobjectlist() {
    return axios.post('object/getAllObjectList')
}

// 查询用户界面设置信息
export function getUserInterface(data) {
    return axios.post('/user/getUserInterface', data)
}

/**
 * 获取用户配置信息
 * @returns 用户配置信息
 */
 export function getUserSetup() {
    return axios.get('/user/getUserSetup')
}

//获取字段
export function getsize(data) {
    return axios.post('emailObject/queryFields', data)
}
//获取相关列表数据及按钮和字段信息
export function GetDetailRelatedItems(data) {
    return axios.post('/objectdetail/getDetailRelatedItems', data)
}
//判断商情是否启用
export function openBusiness() {
    return axios.post('/business/isEnabled')
}
//获取商情列表
export function getBusList(data) {
    return axios.post('/business/search', data)
}
// 视图列表退回（公海池）
export function getReturnSeasPool(data) {
    return axios.post('/marketseaFunction/getReturnSeasPool', data)
}
// 获取视图列表 (左侧下拉菜单列表,) // 获取对象下所有可见的公海池
export function queryMarketSeaList(data) {
    return axios.post('/marketsea/queryMarketSeaList', data)
}
//判断快速新建权限
export function getNewAuth(data) {
    return axios.post('/objectdetail/getObjectPermission', data)
}
// 获取业务机会联系人架构图
export function display(data) {
    return axios.post('/contactHierarchy/display', data)
}
// 权利接口，获取重大事件名称，开始时间，时间触发器-高含宇
export function getMajorEvent(args) {
    return axios.post('majorevent/editStatusWithMajorEventSet', args)
}
// 获取全部符合条件对象重大事件接口-高含宇
export function getAllMajorEvent(args) {
    return axios.post('majorevent/getAllentityMilestone', args)
}
// 重大事件点击已完成接口-高含宇
export function handleCompleteMajor(args) {
    return axios.post('majorevent/markCompletedTriggerMagorEvent', args)
}
// 获取工作订单行项目流程-高含宇
export function getWorkOrderLine(args) {
    return axios.post('workOrderLineItem/getWorkOrderLineItemProcess', args)
}
// 根据记录类型查询报表
export function queryReprtRecordtypeid(data) {
    return axios.post('/report/chart/queryReprtRecordtypeid', data)
}
// 当前是否启用了quickbooks功能  查询QB权限
export function quickbookStart(data) {
    return axios.get('/quickbooks/getQBPermission', data)
}
// 删除类似业务机会
export function getSimilarOpportunityDeleteBookmarksOppDetails(data) {
    return axios.post('/similarOpportunity/deleteBookmarksOppDetails', data)
}
// 判断是否有知识库用户
export function getKnowledgePermissionById(data) {
    return axios.post('/knowledgeDesignate/getKnowledgePermissionById', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}

// 获取对象详细信息
export function getDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
    return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

//产品详情页上添加标准价格
export function addStdPricebookItems(data) {
    return axios.post('/pricebook/addStdPricebookItems', data)
}

//产品详情页上添加价格手册
export function addCustomPricebookItems(data) {
    return axios.post('/pricebook/addCustomPricebookItems', data)
}

//保存价格手册
export function saveProductPricebookItem(data) {
    return axios.post('/pricebook/saveProductPricebookItem', data)
}

//产品详情页编辑价目表
export function editPricebookItem(data) {
    return axios.post('pricebook/editPricebookItem', data)
}

//删除价目表记录
export function deleteItem(data) {
    return axios.post('pricebook/deleteItem', data)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
    return axios.post('activity/getQuickActivityPage', data)
}

// 邮件查找关联日程预约
export function queryAppointments(data) {
    return axios.post('/appointment/queryAppointments', data)
}
// 判断订单是否为已开具发票状态
export function orderStatus(data) {
    return axios.post('/generateInvoiceNew/orderStatus', data)
}
// 获取订单或发票的地址
export function getCustomerAddress(data) {
    return axios.post('/customeraddress/getCustomerAddress', data)
}
// 查询客户是否存在默认客户地址
export function isDefaultAddress(data) {
    return axios.post('/customeraddress/isDefaultAddress', data)
}
// 点击默认按钮更改客户地址默认状态
export function updateAddressStatus(data) {
    return axios.post('/customeraddress/updateAddressStatus', data)
}
// 更新发票报价单地址
export function updateOrderOrInvoiceInfo(data) {
    return axios.post('/customeraddress/updateOrderOrInvoiceInfo', data)
}
// 判断业务机会，报价单，订单，发票，采购订单是否有子产品
export function existRelatedProductInfo(data) {
    return axios.post('/master/existRelatedProductInfo', data)
}
// 删除业务机会，报价单，订单，发票，采购订单关联的子产品记录
export function deleteRelatedProductInfo(data) {
    return axios.post('/master/deleteRelatedProductInfo', data)
}

export function getApprovalHistoryList(param) {
    return axios.post('approval/getApprovalHistoryList', param)
}
// 保存邮件签名
export function getEmailObjectSaveSign(data) {
    return axios.post('/emailObject/saveSign', data)
}
// 查询邮件签名
export function getEmailObjectQuerySign(data) {
    return axios.post('/emailObject/querySign', data)
}
// 获取视图列表记录
export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
  }
// 通用权限查找接口
export function getSystemPermission(data) {
    return axios.post("/user/getSystemPermission", data);
}
// 保存个案团队成员
export function saveCaseteamMember(args) {
    return axios.post("caseteam/saveCaseteamMember", args);
}
export function masterRecordDetailInfo(data) {
    return axios.post('master/masterRecordDetailInfo', data)
}
// 获取 服务资源以及服务区域对象下的所有标准和自定义...
export function getResourceAttributeFields(data) {
    return axios.post("/autoRecommendPolicy/getResourceAttributeFields", data);
}
// 获取 服务资源以及服务区域对象下的标准和自定义的数字/百分比/数字类型的公式字段类型字段
export function getQualificationFields(data) {
    return axios.post("/autoRecommendPolicy/getQualificationFields", data);
}
// 校验筛选条件
export function checkCondition(data) {
    return axios.post("/autoRecommendPolicy/checkCondition", data);
}
// 保存筛选条件
export function saveCondition(data) {
    return axios.post("/autoRecommendPolicy/saveCondition", data);
}

// 根据考勤组获取考勤组关联用户id
export function getUserBYGroup(data) {
    return axios.post("/H5Clock/getUserBYGroup", data);
}
// 保存考勤组上用户相关列表
export function saveUserInGroup(data) {
    return axios.post("/H5Clock/saveUserInGroup", data);
}
