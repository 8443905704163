<template>
  <div>
    <el-dialog :title="titles" :visible.sync="dialogVisible" width="30%">
      <div>
        <span><el-input v-model="input" disabled></el-input></span>
        <p style="padding-top: 20px;font-size: 12px;">{{content}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          <!-- 取消 -->
          {{ $t('button_partner_cancel') }}
        </el-button>
        <el-button type="primary" @click="submit">
          <!-- 同步 -->
          {{ $t('lable.quote.syncing.syncingbutton') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    GetQuoteSyncingFromOpportunity,
    GetQuoteSyncingToOpportunity,
  } from "./api.js";
  export default {
    data() {
      return {
        dialogVisible: false,
        titles: this.$i18n.t('vue_label_quot_syncfrom'),//从业务机会同步
        input: "",
        content: this.$i18n.t('lable.quote.syncingfrom.firstsync.description'),//业务机会产品将替代报价单明细表
        info: {}
      }
    },
    methods: {
      async gettitle(val) {
        this.info = val
        if (val.name === "fromopportunity") {
          this.input = this.$i18n.t('lable.quote.syncingfrom.firstsync') //"从业务机会同步至报价单"
          this.content = this.$i18n.t('lable.quote.syncingfrom.firstsync.description')//业务机会产品将替代报价单明细表
        } else if (val.name === 'startsyncing ') {
          this.input = this.$i18n.t('lable.quote.syncing.firstsync')//"报价单同步至业务机会"
          this.content = this.$i18n.t('lable.quote.syncing.firstsync.description') //报价单明细将替代业务机会产品
        }
        this.titles = val.label
        this.dialogVisible = true
      },
      async submit() {
        if (this.info.name === "fromopportunity") {
          let params = {
            quoteId:this.$route.params.id
          }
          let res = await GetQuoteSyncingFromOpportunity(params)
          if(res.result){
            this.dialogVisible = false
            // 同步成功
            this.$message.success(this.$i18n.t('label.companyprofile.syncsuccess'))
          }
        } else if (this.info.name === 'startsyncing ') {
          let params = {
            quoteId:this.$route.params.id
          }
          let res = await GetQuoteSyncingToOpportunity(params)
          if(res.result){
            this.dialogVisible = false
            // 同步成功
            this.$message.success(this.$i18n.t('label.companyprofile.syncsuccess'))
          }
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-dialog__header {
    border: none;
    padding: 16px 14px 0px 21px;

    .el-dialog__title {
      line-height: 30px;
      font-size: 14px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #686868;
    }
  }

  ::v-deep .el-dialog__body {
    padding: 15px 30px 0px 20px;
    border: none;
  }

  ::v-deep .el-input {
    width: 80%;
    padding-left: 10px;

    .el-input__inner {
      height: 28px;
    }
  }
</style>