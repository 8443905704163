<template>
  <div class="top">
    <div class="home" v-show="!isQuery && !fullscreenLoading">
      <div class="noPermissionBox">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-noPermission"></use>
        </svg>
        <p class="noPermissionTip">
          <!-- 抱歉，您无权限查看该条数据，请联系系统管理员。 -->
          {{ $t("vue_label_notice_nopermission_view") }}
        </p>
        <p style="text-align: center">
          <el-button type="primary" size="mini" @click="goBack">
            <!-- 返回上一级 -->
            {{ $t("label.goback") }}
          </el-button>
        </p>
      </div>
    </div>
    <div class="home" v-show="isQuery" id="contentMainBox" ref="home">
      <!-- 用户对象新增的头部 -->
      <detailsPersonal v-if="!isShowType" :id="id" :fieldValue="userName">
      </detailsPersonal>

      <div :class="routerName === 'DETAIL' ? 'contentBox' : 'contentBoxs'">
        <div class="box_top" v-if="isShowType">
          <i class="iconfont iconjiantou-zuo left" @click="goBack"></i>
          <!-- 基本信息 -->
          <div>
            <svg class="icon box_header" aria-hidden="true">
              <use :href="'#icon-' + tabStyle || '#icon-hometab'"></use>
            </svg>
            <div class="title">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="goBigUrl">{{
                  $store.state.channel.tabName
                }}</el-breadcrumb-item>
                <el-breadcrumb-item @click.native="goUrl">{{
                  $route.query.channel
                }}</el-breadcrumb-item>
              </el-breadcrumb>

              <!--                            <span class="title_top">{{ eventTap || tabName }}</span>-->
              <!-- <br /> -->
              <p class="title_main">
                {{ bigTitle[0] ? bigTitle[0].fieldValue : "" }}
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="content"
                  placement="top"
                  v-if="isCustomer"
                >
                  <i
                    style="
                      margin-left: 0 0 -2px 10px;
                      cursor: pointer;
                      position: relative;
                      top: -1px;
                    "
                    @contextmenu.prevent.stop="routers"
                    @click.prevent.stop="routers"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 18px; height: 18px"
                    >
                      <use href="#icon-cengji"></use>
                    </svg>
                  </i>
                </el-tooltip>
              </p>
            </div>
            <div class="header_right">
              <el-button-group
                style="margin-top: 16px"
                v-if="isShowGanttButton"
              >
                <el-button
                  size="mini"
                  class="button"
                  @click="toGannt('isGantt')"
                >
                  {{ $t("label.projectManagement.gantt") }}
                </el-button>
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="hover"
                >
                  <div class="xiala" slot="reference">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-blue_sj"></use>
                    </svg>
                  </div>
                  <ul class="moreButton" style="width: 100px">
                    <li
                      style="cursor: pointer"
                      @click="toGannt('kanbanTaskPage')"
                    >
                      {{ $t("vue_label_commonobjects_view_kanban_view") }}
                    </li>
                    <li style="cursor: pointer" @click="toGannt('isGantt2')">
                      {{ $t("label.projectManagement.Resource.usage.view") }}
                    </li>
                  </ul>
                </el-popover>
              </el-button-group>
              <el-button-group style="margin-right: 10px">
                <el-button
                  size="mini"
                  class="button"
                  @click="setFollow(follow)"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-show="
                      this.follow === $t('label.chatter.follow') ||
                      this.follow === $t('label.chatter.followed')
                    "
                    style="width: 12px; margin-top: -3px; margin-right: 3px"
                  >
                    <use
                      :href="
                        this.follow === $t('label.chatter.follow')
                          ? '#icon-gz'
                          : '#icon-yes'
                      "
                    ></use>
                  </svg>
                  <span @mouseover="mouseover" @mouseout="mouseout">{{
                    follow
                  }}</span>
                </el-button>
                <!-- 群策 勿删 -->
                <el-button
                  size="mini"
                  class="button"
                  @click="judgeIsHas"
                  v-if="
                    isShowChat &&
                    (this.objId === 'account' || this.objId == 'opportunity') &&
                    //是否为伙伴云用户
                    !this.Partnercloud
                  "
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="width: 14px; margin-top: -3px; margin-right: 3px"
                  >
                    <use href="#icon-qc"></use>
                  </svg>
                  <span>
                    {{ $t("label_mobile_buttom_team") }}
                  </span>
                </el-button>
              </el-button-group>
              <el-button-group style="margin-top: 16px">
                <el-button class="button" size="mini" @click="del()">
                  {{ $t("label.batchDelete") }}
                </el-button>
              </el-button-group>
              <!--审批-->
              <el-button-group style="margin-top: 16px">
                <el-button
                  class="button"
                  style="margin-left: 10px"
                  size="mini"
                  v-for="(item, index) in showHistoryBtn"
                  :key="index"
                  @click="historyDeal(item)"
                >
                  {{ item.label }}
                </el-button>
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="hover"
                  v-if="hideHistoryBtn.length > 0"
                >
                  <div class="xiala" slot="reference">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-blue_sj"></use>
                    </svg>
                  </div>
                  <ul class="moreButton" style="width: 100px">
                    <li
                      v-for="(item, index) in hideHistoryBtn"
                      :key="index"
                      @click="historyDeal(item)"
                      style="cursor: pointer"
                    >
                      {{ item.label }}
                    </li>
                  </ul>
                </el-popover>
              </el-button-group>

              <div class="dayin">
                <svg class="icon" aria-hidden="true" @click="printDetail">
                  <use href="#icon-dayin2"></use>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
      <!-- </el-card> -->
      <div
        :class="routerName === 'DETAIL' ? 'clears' : ''"
        v-if="isShowType"
      ></div>
      <el-card class="box-card" v-if="isShowType">
        <Wang-skeleton
          v-if="isSkeleton"
          type="custom"
          :childrenOption="childrenOptions"
          style="background: #fff"
        />
        <!-- 简介 -->
        <div class="introduce" v-else>
          <div
            class="introduce_content"
            v-for="(item, index) in heightLight"
            :key="index"
          >
            <div v-if="item.isShowMenus === true">
              <span class="introduce_title"
                >{{ item.fieldLabel }}
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="content"
                  placement="top"
                  v-if="isCustomer"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="
                      item.fieldName === 'parent' || item.fieldName === 'zsss'
                        ? 'true'
                        : false
                    "
                    @contextmenu.prevent.stop="routers"
                    @click.prevent.stop="routers"
                  >
                    <use href="#icon-cengji"></use>
                  </svg>
                </el-tooltip>
              </span>
              <br />
              <!-- 不是005开头的就是队列 ,队列不跳转-->
              <span
                :class="[
                  'introduce_cet',
                  item.fieldType === 'Y' ||
                  item.fieldType === 'M' ||
                  item.fieldType === 'MR'
                    ? 'briefDetail_s'
                    : '',
                ]"
                @click="
                  item.fieldType === 'Y' ||
                  item.fieldType === 'M' ||
                  item.fieldType === 'MR'
                    ? briefDetail(item)
                    : ''
                "
              >
                <em
                  style="font-style: normal"
                  v-if="item.fieldType === 'F' && item.fieldValue"
                  >{{ item.fieldValue | datetimeFormat(countryCode) }}</em
                >
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldType === 'D' && item.fieldValue"
                  >{{ item.fieldValue | dateFormat(countryCode) }}</em
                >
                <em style="font-style: normal" v-else>{{ item.fieldValue }}</em>
              </span>

              <!-- if(item.fieldType === 'F'){
                              item.fieldValue = item.fieldValue|this.datetimeFormat(this.countryCode)
                            } -->
            </div>
          </div>
        </div>
      </el-card>
      <!-- ROI卡片 -->
      <roiCard v-show="ROIflag" ref="roiCard"></roiCard>

      <!-- 进度条 -->
      <Progress
        v-if="isShowType"
        ref="Progress"
        :isconvert="isconvert"
        :viewId="viewId"
        :data-id="id"
        :isEdit="isEdit"
        :inlineedit="inlineedit"
        :canEditOwner="isCanEditOwner"
        :bigTitle="bigTitle"
        :prefixs="prefix"
        :objId="objId"
        :objectApi="objectApi"
      >
      </Progress>
      <div id="table-panel" style="width: 100%; margin-top: 10px">
        <!-- 表格组件 -->
        <div class="content-main">
          <!-- showTable -->
          <el-table
            :height="contentBoxHeight"
            ref="tablePanel"
            :data="tableDataList"
            row-key="id"
            default-expand-all
            :border="true"
            aria-activedescendant=""
            v-loading="pictLoading"
            @select-all="selectAll"
            @selection-change="selectionChange"
            @select="select"
            @row-click="rowClick"
            @cell-dblclick="cellDoubleClick"
            @toggleSelection="clearSelection"
            :empty-text="$t('label.dashboard.noRecord')"
            :style="{ width: '100%', 'max-height': contentBoxHeight }"
          >
            <el-table-column lable="" type="index" width="50">
              <template slot="header" >
                <svg
                  class="icon admin_s"
                  aria-hidden="true"
                  ref="iconTool"
                  id="iconTool"
                >
                  <use href="#icon-gl"></use>
                </svg>
              </template>
            </el-table-column>
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column
              :label="$t('label.members.activity')"
              width="180"
              prop="name"
            >
            </el-table-column>
            <el-table-column prop="phone" :label="$t('label.department.phone')">
            </el-table-column>
            <el-table-column prop="email" :label="$t('UG.Lead.005')">
            </el-table-column>
            <el-table-column
              prop="status"
              :label="$t('label.import.index.state')"
            >
            </el-table-column>
            <!-- 操作列 -->
            <el-table-column width="80" align="center">
              <template slot-scope="scope">
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_small"
                  trigger="hover"
                  :visible-arrow="false"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    slot="reference"
                    style="cursor: pointer; width: 20px; border-radius: 3px"
                  >
                    <use href="#icon-xiala"></use>
                  </svg>
                  <ul>
                    <li
                      v-for="(btnItem, index) in operationButtonList"
                      :key="btnItem.action"
                      @click="handleTableAction(scope, btnItem)"
                    >
                      <span class="cursor-pointer" :key="index">
                        {{ btnItem.label }}
                      </span>
                    </li>
                  </ul>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 共享弹窗 -->
    <Share ref="isShare" :shares="shares"> </Share>

    <!-- pdf预览 -->
    <pdf-viewer
      :is-show="pdfViewerAttr.isShow"
      :top="3"
      :title="pdfViewerAttr.title"
      :pdfUrl="pdfViewerAttr.pdfUrl"
      :fullScreen="false"
      @cancel="closeViewerDialog"
      @saveToAppendix="saveToAppendix"
    >
    </pdf-viewer>

    <!-- 共享弹窗 -->
    <synchro ref="synchro"></synchro>
    <el-dialog
      :title="
        objId === 'account'
          ? $t('vue_label_commonobjects_detail_group_strategy')
          : $t('vue_label_commonobjects_detail_group_opportunitystrategy')
      "
      :visible.sync="isShowTag"
      width="40%"
      :append-to-body="true"
      custom-class="qunce-dialog"
    >
      <div>
        <!-- 加入后，该群策将显示在您的群策列表，群策动态会实时推送给您。 -->
        {{ $t("vue_label_commonobjects_detail_real_time") }}
      </div>
      <el-button @click="cancelDia" class="cancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="joinGroup">
        <!-- 加入 -->
        {{ $t("chatter_group_join") }}
      </el-button>
    </el-dialog>

    <!-- 删除数据和解除锁定 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogAttr.title"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 16px; font-weight: bold">
        {{ dialogAttr.content }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button @click="confirm" type="primary" size="mini">
          <!-- 确认 -->
          {{ $t("label_chatter_ok") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 伙伴云 -->
    <el-dialog
      custom-class="partnercloud"
      :title="$t('label_partner')"
      :visible.sync="isPartnerCloud"
      width="69.3%"
      :before-close="handleCloses"
    >
      <el-table :data="tableData" border style="width: 100%">
        <!-- 名称 -->
        <el-table-column
          prop="name"
          :label="$t('label_chatter_name')"
          width="180"
        >
        </el-table-column>
        <!-- 描述 -->
        <el-table-column
          prop="description"
          :label="$t('label_file_des')"
          width="180"
        >
        </el-table-column>
        <el-table-column label="URL">
          <template slot-scope="scope">
            <el-link type="primary" :href="scope.row.url" target="_blank">{{
              scope.row.url
            }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isPartnerCloud = false">
          <!-- 取 消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- :prefix="prefix"
      :id="id"
      :objectApi="objectApi"
      :relation-field-id="suspenList.fieldId"
      :object-name="suspenList.objLabel" -->

    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="删除"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- 确认删除该条数据吗 -->
      <span style="font-size: 14px; color: #080707">{{
        $t("label.weixin.confirm.delete")
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button @click="confirmDel" class="el-button--primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- 置顶按钮 -->
    <svg class="icon backtop" aria-hidden="true" @click="backtop">
      <use href="#icon-top_s"></use>
    </svg>
    <div style="height: 0; overflow: hidden">
      <ApprovalHistory
        v-show="true"
        ref="uponDetailHistory"
        :historyID="id"
        :itemTitle="fieldValue"
      >
      </ApprovalHistory>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import {
  GetBrief,
  GetTagsByRecordId,
  GetDelete,
  GetSharegetShareRuleList,
  SavePdfToFile,
  getObjectGetObjectInfo,
} from "../api";
import ApprovalHistory from "@/views/approval/components/approval-history.vue";
import roiCard from "@/views/commonObjects/detailChild/ROIcard.vue";
import { Share, Progress, PdfViewer } from "@/components/index.js";
import job from "@/views/chat/js/request.js";
import synchro from "@/components/synchro/index.vue";
import * as CommonObjApi from "../api";
import { setFollow, isFollow } from "../api";
import detailsPersonal from "@/views/colleagueCircle/components/details/DetailsPersonal.vue";
import {
  getPartnerGetPartnerPermission,
  getPartnerPartnerEnableAccount,
  getPartnerPartnerEnableContact,
  getPartnerQueryAllPartnerByLogin,
} from "@/views/marketList/api.js";
import VueCookies from "vue-cookies";
import Bus from "@/mixin/bus.js";
import debounce from "lodash.debounce";
import * as Time from "@/utils/time";

export default {
  name: "Activity",
  components: {
    Share,
    Progress,
    roiCard,
    PdfViewer,
    synchro,
    ApprovalHistory,
    detailsPersonal,
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      delFlg: false,
      selectedList: [],
      tableDataList: [],
      ids: "",
      // 操作按钮的下拉框按钮数据
      operationButtonList: [
        { action: "edit", label: "编辑" },
        { action: "delete", label: "删除" },
      ],
      pictLoading: false,
      detailLayoutMode: "mode1",
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      orgId: this.$store.state.userInfoObj.orgId,
      //群策按钮是否显示
      isShowChat:
        localStorage.getItem("chatOpen") == "true" &&
        localStorage.getItem("login") == "success",
      dialogAttr: {
        title: "",
        content: "",
      },
      inlineedit: false,
      dynamicType: false, //更改右侧动态tab
      isShowType: true, //判断用户详情页面
      fullscreenLoading: false,
      qunceType: "",
      //加入群策需要的数据
      qunceData: {},
      //显示加入群策弹框
      isShowTag: false,
      //当前群策的群ID
      detailGroupId: "",
      //客户群策所有人
      groupStrategyOwnerid: {}, //获取用户的环信ID
      information: {},
      detail: "detail",
      content: this.$i18n.t("vue_label_commonobjects_detail_company_hierarchy"), //公司层级结构
      isCustomer: false,

      customFlag: false,
      icons: "el-icon-d-arrow-right",
      isHeight: false,
      height: "100%",
      active: 0,
      activeName: "first",
      isTrue: "active",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      labelName: [],
      labelArr: [],
      buttonList: [],
      heightLight: [],
      // showBtn: false,
      moreButton: [],
      showButton: [],
      color: "#fff",
      hong: "#F6817B",
      huang: "#FEC366",
      lan: "#62A5F3",
      hui: "#C8C6C6",
      lv: "#7cedcc",
      id: this.$route.query.id || this.dataId,
      tagId: this.$route.query.tagId,
      objectApi: "",
      bigTitle: [],
      fieldValue: "",
      tabName: "",
      shares: [],
      ROIflag: false,
      follow: "",
      pdfViewerAttr: {
        title: this.$i18n.t("pagecreator_button_preview"), //"预览",
        isShow: false,
        pdfUrl: null,
      },
      objId: this.$route.query.objId || this.objid,
      prefix: "",
      // 右上角审批按钮
      showHistoryBtn: [],
      hideHistoryBtn: [],
      printVisible: false, //详情打印弹窗
      isconvert: "",
      viewId: "",
      objArr: [],
      pricebookArr: [],
      isQuery: null, // 记录可查权限
      isCanEditOwner: "", //判断是否有更改所以人权限
      isTag: "",
      routerName: "",
      activeList: [],
      isLocked: false, //是否锁定
      isEdit: false, // 编辑权限
      postShowType: "detailInfo", //查询帖子的类型
      recordName: "",
      copyType: "",
      userName: "",
      dialogVisible: false, //删除弹窗给
      childrenOptions: [
        // 列表
        {
          type: "list",
          options: {
            row: 2,
            column: 3,
            width: "80px",
            height: "16px",
            backgroundColor: "#f3f2f2",
            justifyContent: "center",
            active: false,
            itemRowMargin: "8px 8px 8px 26px",
            firstWidth: "50px",
            lastWidth: "80px",
            alignItems: "center",
          },
        },
      ],
      isSkeleton: false,
      styleOption: [
        // 列表
        {
          type: "list",
          options: {
            row: 2,
            column: 2,
            width: "44.9vw",
            height: "20px",
            backgroundColor: "#f3f2f2",
            justifyContent: "center",
            active: false,
            itemRowMargin: "10px 28px",
            firstWidth: "44.9vw",
            lastWidth: "44.9vw",
            alignItems: "center",
          },
        },
      ],
      isStyleType: false,
      partner: [],
      ismanagePartnerUser: null,
      ispartner: null,
      dataList: [],
      isPartnerCloud: false,
      tableData: [],
      userId: "",
      tabStyle: "",
      isShowGanttButton: false, //控制显示甘特图按钮
      screenWidth: document.body.clientWidth,
      timer: false,
      myid: localStorage.getItem("myid"),
      realObjId: "", //对象的objId
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      operatingTimeObj: {}, // 服务区域下的操作时间
    };
  },
  beforeRouteEnter(to, from, next) {
    if (VueCookies.get("detailLayoutMode") === null) {
      VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
    }
    next((vm) => {
      // 通过 `vm` 访问组件实例
      vm.detailLayoutMode = VueCookies.get("detailLayoutMode");
      // vm.$refs.listChild && vm.$refs.listChild.setRelationNameWidth()
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.viewId) {
      this.viewId = to.query.viewId;
    }
    this.id = to.params.id;
    this.init();
    document.getElementById("contentMainBox").scrollTop = 0;
    this.$nextTick(() => {
      this.$refs.listChild.init();
    });
    next();
  },
  created() {
    if (this.$route.path.includes("activeMember")) {
      this.ROIflag = false;
    }
    this.isSkeleton = true;
    // this.isStyleType = true
    this.routerName = this.$route.params.oprateType;
    this.viewId = this.$router.history.current.query.viewId;
    this.init();
    this.isFollow();
  },
  mounted() {
    this.getIsHasGroup();
    if (VueCookies.get("detailLayoutMode") === null) {
      VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
    }
    this.detailLayoutMode = VueCookies.get("detailLayoutMode");
    this.$Bus.$on("deliver-group-strategy-ownerid", this.ebFn1);
    // 服务区域下的操作时间
    this.$Bus.$on("service-territory-data", this.ebFn2);
    //获取窗口宽度
    Bus.$on("windowResize",this.ebFn3);
    //提交待审批后局部刷新页面
    this.$Bus.$on("approvalrefresh",this.ebFn4 );
  },
  beforeDestroy() {
    this.$Bus.$off("deliver-group-strategy-ownerid", this.ebFn1);
    this.$Bus.$off("service-territory-data", this.ebFn2);
    Bus.$off("windowResize",this.ebFn3);
    this.$Bus.$off("approvalrefresh",this.ebFn4 );
  },
  methods: {
    ebFn1(res){
      this.groupStrategyOwnerid = res;
    },
    ebFn2(res){
      this.operatingTimeObj = res;
    },
    ebFn3(offsetHeight, offsetWidth){
      this.screenWidth = offsetWidth;
    },
    ebFn4(){
      this.refreshAll();
    },
    getListTable() {
      //如果是表单营销推广调另一个接口
      if(this.$route.query.relatedlistId === 'aee220126caf49997980'){
        let data = {
          id:this.$route.query.id,
          layoutId:this.$route.query.layoutId,
          relatedlistId:'aee201200108890new3K',
        }
        // eslint-disable-next-line no-undef
        getCampaignmember(data).then((res)=>{
          if (res.result) {
            this.tableDataList = res.data;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        })
      }else{
        CommonObjApi.getMarketingActivities({
          channel: this.$route.query.tagId,
          campaignId: this.$route.query.id,
        }).then((res) => {
          if (res.result) {
            this.tableDataList = res.data;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      }
    },
    handleTableAction(scope, btnItem) {
      let id = scope.row.id;
      if (btnItem.action == "edit") {
        this.edit(id);
      } else if (btnItem.action == "delete") {
        this.del(id);
      }
    },
    edit(id) {
      this.id = id;
      this.$router.push({
        path: `/commonObjects/detail/${this.id}/DETAIL`,
        query: { id: this.id },
      });
    },
    // 删除
    del(id) {
      if (id) {
        this.id = id;
        this.delFlg = true;
        this.dialogVisible = true;
      } else {
        if (this.ids === "") {
          this.$message.info(this.$i18n.t("lable.opppdt.selPdt.leastone"));
        } else {
          this.delFlg = false;
          this.dialogVisible = true;
        }
      }
    },
    // 确认删除数据
    confirmDel() {
      this.dialogVisible = false;
      if (this.delFlg) {
        CommonObjApi.del({
          id: this.id,
          objectApi: "campaignmember",
        }).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            });
            this.getListTable();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      } else {
        if (this.selectedList.length > 200) {
          this.$message.info(
            this.$i18n.t("vue_label.commonobjects.view.up.to.data")
          );
        } else {
          CommonObjApi.batchDelete({
            ids: this.ids,
            prefix: "014",
          }).then((res) => {
            if (res.result) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("label_tabpage_delsuccessz"),
              });
              this.getListTable();
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
        }
      }
    },
    // 全选事件
    selectAll(val) {
      this.selectedList = val;
      let ids = "";
      for (var i = 0; i < val.length; i++) {
        ids += val[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.ids = ids;
    },
    // 表格滚动懒加载
    // eslint-disable-next-line no-unused-vars
    clearSelection(rows) {
      //   if (rows) {
      //     rows.forEach((row) => {
      //       this.$refs.tablePanel.toggleRowSelection(row);
      //     });
      //   } else {
      //     this.$refs.tablePanel.clearSelection();
      //   }
    },
    // 表格选中状态
    selectionChange(val) {
      this.selectedList = val;
      let ids = "";
      for (var i = 0; i < val.length; i++) {
        ids += val[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.ids = ids;
    },
    select() {},
    rowClick() {
      //   this.$emit("rowClick", row, column);
    },
    // 表格被双击
    cellDoubleClick() {},
    hrefUrl() {
      // // 项目管理-实际工作清单点击日期跳转详情
      // if (this.$cookies.get('activeTabObjId') === 'projectworklist' && item.schemefieldType === "D") {
      //   return row[item.schemefieldName] ? `#/commonObjects/detail/${row.id}/DETAIL` : ''
      // } else {
      //   return row[item.schemefieldName] ? `#/commonObjects/detail/${row[item.schemefieldName]}/DETAIL` : ''
      // }
    },
    //根据视口宽度计算简介内容展示
    meunsFilter() {
      let itemWidth = 0;
      let intLen = document.getElementsByClassName("introduce_content");
      for (var i = 0; i <= intLen.length; i++) {
        if (intLen[i]) {
          itemWidth = intLen[i].offsetLeft + 300;
        }
        if (itemWidth >= this.screenWidth) {
          if (this.heightLight[i]) {
            this.heightLight[i].isShowMenus = false;
          }
        } else {
          if (this.heightLight[i]) {
            this.heightLight[i].isShowMenus = true;
          }
        }
      }
    },
    // 获取从子组件list传过来的详情页跳转用户名
    personsNameMethod(name) {
      this.userName = name;
      document.title = this.$setTitle(`${name}`);
    },
    // // 刷新整个页面
    // refreshAll() {
    //     this.init();
    //     document.getElementById("contentMainBox").scrollTop = 0;
    //     this.$nextTick(() => {
    //         this.$refs.listChild.init();
    //         this.$refs.roiCard.init();
    //         this.$refs.uponDetailHistory.getApprovalPreview();
    //     });
    // },
    joinGroup() {
      if (this.qunceType == "exist") {
        this.$Bus.$emit("create-strategy-groupexist", this.qunceData);
        this.isShowTag = false;
      } else if (this.qunceType == "notexist") {
        if (Object.keys(this.groupStrategyOwnerid).length > 0) {
          if (this.groupStrategyOwnerid.value == "") {
            this.$message({
              showClose: true,
              type: "warning",
              message: this.$i18n.t(
                "vue_label_commonobjects_detail_a_group_strategy"
              ), //该条数据缺少所有人,无法创建群策
            });
            return false;
          }
          if (this.groupStrategyOwnerid.id == localStorage.getItem("myid")) {
            this.qunceData.groupIdArr = [
              JSON.parse(localStorage.getItem("userInfo")).userId,
            ];
            this.qunceData.groupName =
              this.bigTitle && this.bigTitle[0].fieldValue;
            this.qunceData.groupNames = localStorage.getItem("username");
            this.qunceData.userIdArr = localStorage.getItem("myid");
            this.$Bus.$emit(
              "create-strategy-allnotexist",
              this.qunceData,
              this.id
            );
          } else {
            this.qunceData.groupIdArr = [
              JSON.parse(localStorage.getItem("userInfo")).userId,
            ];
            this.qunceData.groupName =
              this.bigTitle && this.bigTitle[0].fieldValue;
            this.qunceData.userIdArr = [localStorage.getItem("myid")];
            this.getUserInfor(this.groupStrategyOwnerid.id, this.qunceData);
          }
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_customer_details"
            ), //正在加载客户详情
          });
          return false;
        }
        this.isShowTag = false;
      }
    },
    cancelDia() {
      this.isShowTag = false;
    },
    //判断业务机会或客户是否创建了群策
    async getIsHasGroup() {
      let result = await job.judgeIsHasStrategy(this.id);
      if (
        result.result == true &&
        result.data &&
        result.data.strategyGroupId &&
        result.data.strategyGroupId != ""
      ) {
        this.detailGroupId = result.data.strategyGroupId;
      }
    },
    //群策获取客户信息
    judgeIsHas: debounce(
      async function () {
        let result = await job.judgeIsHasStrategy(this.id);
        if (result.result == true && result.data) {
          let item = {};
          item.strategyType = result.data.objid;
          if (
            result.data.strategyGroupId &&
            result.data.strategyGroupId != ""
          ) {
            let groupMessage = await job.getgroupmessage(
              result.data.strategyGroupId
            );
            if (groupMessage.result == true && groupMessage.data) {
              item.name = groupMessage.data.name;
              item.id = result.data.strategyGroupId;
            }
          } //存在群策，并且已经加入
          if (
            result.data.existStrategyGroup == "true" &&
            result.data.joinStrategyGroup == "true"
          ) {
            this.$Bus.$emit("create-strategy-allexist", item);
          } //存在群策，但没有加入
          if (
            result.data.existStrategyGroup == "true" &&
            result.data.joinStrategyGroup == "false"
          ) {
            //打开群策对应的群
            this.isShowTag = true;
            this.qunceType = "exist";
            this.qunceData = item;
          } //不存在群策
          if (
            result.data.existStrategyGroup == "false" &&
            result.data.joinStrategyGroup == "false"
          ) {
            this.isShowTag = true;
            this.qunceType = "notexist";
            this.qunceData = item;
          }
        }
      },
      3000,
      { leading: true, trailing: false }
    ),
    async getUserInfor(id, item) {
      let userId = await job.getUserInformation(id);
      if (userId.result == true && userId.data) {
        item.groupIdArr.push(userId.data[0].imUserId);
        item.groupNames = userId.data[0].name;
        item.userIdArr.push(userId.data[0].id);
        item.userIdArr = item.userIdArr.join();
        this.$Bus.$emit("create-strategy-allnotexist", item, this.id);
      }
    },
    init() {
      this.isQuery = null;
      this.fullscreenLoading = true;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // 获取记录查看权限，无权限时给提示，可返回上一级
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          this.isEdit = res.data.isEdit;
          this.realObjId = res.data.objId;
          this.isLocked = res.data.isLocked;
          // this.isDelete = res.data.isDelete
          this.isCanEditOwner = res.data.canEditOwner;
          loading.close();
          this.fullscreenLoading = false;

          if (res.data.isQuery) {
            this.isQuery = true;
            this.isShowType = true;
            if (res.data.objId === "user") {
              // this.getCurrentUser();
              // this.personsNameMethod();
              this.isShowType = false;
              this.isQuery = true;
              this.dynamicType = true;
              this.activeName = "second";
              this.$refs.dynamicPublics.personDetailPage();
            } else {
              this.getListTable();
              this.getBrief();
              this.getTagsByRecordId();
            }
          } else {
            this.isQuery = false;
          }
        } else {
          loading.close();
          this.fullscreenLoading = false;
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 鼠标悬浮
    mouseover() {
      // 当已关注后鼠标悬浮变成取消关注
      if (this.follow === this.$i18n.t("label.chatter.followed")) {
        this.follow = this.$i18n.t("vue_label_CCChat_unfollow");
      }
    },
    // 当按钮为取消关注时鼠标离开变成已关注
    mouseout() {
      if (this.follow === this.$i18n.t("vue_label_CCChat_unfollow")) {
        this.follow = this.$i18n.t("label.chatter.followed");
      }
    },
    // 判断是否关注
    async isFollow() {
      let params = {
        id: this.id,
      };
      let res = await isFollow(params);
      if (res.data.isFollow) {
        // 已关注
        this.follow = this.$i18n.t("label.chatter.followed");
      } else {
        //关注
        this.follow = this.$i18n.t("label.chatter.follow");
      }
    },
    // 点击关注 取消关注
    async setFollow(val) {
      let params = {};
      if (val == this.$i18n.t("label.chatter.follow")) {
        params = {
          id: this.id,
          followType: "record",
          followStatus: "1", //关注
        };
      } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
        params = {
          id: this.id,
          followType: "record",
          followStatus: "0", //取消关注
        };
      }

      let res = await setFollow(params);
      if (res.result) {
        if (val == this.$i18n.t("label.chatter.follow")) {
          // 按钮为关注时点击后变成已关注
          this.follow = this.$i18n.t("label.chatter.followed");
        } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
          // 按钮为取消关注时点击后变成关注
          this.follow = this.$i18n.t("label.chatter.follow");
        }
      }
    },

    // async setAlreadyFollow() {
    //   let params = {
    //     id: this.id,
    //     followType: "record",
    //     followStatus: "0",
    //   };
    //   let res = await setFollow(params);
    //   if (res.result) {
    //     this.follow = "关注";
    //   }
    // },
    //关闭添加标签
    closeAddBtn(n) {
      this.customFlag = n;
    },
    // 获取新添加的标签
    determineFun() {
      this.customFlag = false;
      this.getTagsByRecordId();
    },
    getDelLabelArr(msg) {
      this.labelArr = msg;
      this.$refs.listChild.loadingDetail = true;
      this.$refs.listChild.operation = "DETAIL";
      this.$refs.listChild.getFormAttr();
    },
    customBtn() {
      // this.customFlag = true;
      if (this.labelArr.length !== 0) {
        this.$refs.addlabel.labelArrs(this.labelArr);
      }
      this.$refs.addlabel.tagApi(this.objId, this.objectApi);
      this.$refs.addlabel.isShows();
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    getTagsByRecordId() {
      GetTagsByRecordId({
        recordId: this.id,
      }).then((res) => {
        this.labelArr = res.data;
      });
    },
    resetObjectInfo(data) {
      this.objId = data.objId;
      // this.objectApi = data.objectApi;
      this.prefix = data.prefix;
      if (data.objectApi === "Account") {
        this.isCustomer = true;
        //客户层级结构
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_customer_hierarchy"
        );
      } else if (data.objectApi === "Contact") {
        this.isCustomer = true;
        // 联系人层级结构
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_contact_hierarchy"
        );
      } else if (data.objectApi === "campaign") {
        this.isCustomer = true;
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_market_hierarchy"
        ); //"市场层级结构";
      } else {
        this.isCustomer = false;
      }
    },
    //获取标签
    getBrief() {
      this.bigTitle = [];
      this.tabName = "";
      this.tabStyle = "";
      GetBrief({ id: this.id }).then((res) => {
        if (res.result && res.returnCode === "1") {
          if (res.data) {
            this.objectApi = res.data.objapi;
            this.objId = res.data.objid;
            this.$cookies.set("parentObjectApi", res.data.objapi, { sameSite: 'Strict' }); //存储父对象的ObjectApi、详情关联列表-实际工作清单新建使用
            this.buttonList = [];
            // 判断是否返回提交待审批
            let result = res.data.button.some((item) => {
              if (item.name === "Submit") {
                return true;
              }
            });
            this.showSubmitBtn(result, res.data.approvalButtonMap);

            res.data.button.forEach((item) => {
              if (item.name == "Share" && this.isCanEditOwner) {
                //共享
                this.buttonList.push(item);
              } else if (
                item.name == "unlock" ||
                item.name == "Edit" ||
                item.name == "Delete" ||
                item.name == "PrintQuote" ||
                item.name == "fromopportunity" ||
                item.name == "startsyncing" ||
                item.name == "Lead_Convers" ||
                item.name == "Lead Convers" ||
                item.name == "Clone" ||
                item.iscustom === "1"
              ) {
                // item.iscustom === "1"
                // 解锁、编辑、删除、报价单打印、从业务机会同步、同步到业务机会、潜在客户转化、复制、自定义按钮
                this.buttonList.push(item);
              }
            });

            //编辑 删除 共享 转换 打印报价单 从业务机会同步 同步至业务机会 提交待审批 -调回批准请求
            //项目管理系统
            // if (
            //   sessionStorage.getItem('application') === 'ace2020D253B3984Or6Q'
            // ) {
            //   this.showButton = this.buttonList.slice(0, 2);
            // } else {
            this.showButton = this.buttonList.slice(0, 3);
            // }
            this.moreButton = this.buttonList.slice(3);
            if (
              res.data.objapi === "Account" ||
              res.data.objapi === "Contact"
            ) {
              this.getPartnerGetPartnerPermissions(res.data.objapi);
            }
            this.heightLight = JSON.parse(JSON.stringify(res.data.heigthLight));
            this.heightLight.forEach((item) => {
              this.$set(item, "isShowMenus", true);
            });
            this.bigTitle = res.data.title;
            this.tabName = res.data.label;
            this.tabStyle = res.data.tabStyle ? res.data.tabStyle : "";
            this.fieldValue = this.bigTitle[0].fieldValue;
            this.objectIcon();
            this.$nextTick(() => {
              this.meunsFilter();
            });

            // 设置title标题
            if (this.fieldValue) {
              document.title = this.$setTitle(`${this.fieldValue}`);
            }
          }
          this.isSkeleton = false;
          // this.isStyleType = false
          //获取roi卡片数据
          if (this.objectApi && this.objectApi === "campaign") {
            this.$refs.roiCard && this.$refs.roiCard.init();
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    getHistorySubmit() {
      let obj = {
        id: "",
        label: this.$i18n.t("label.submitforapproval"),
        hisId: "0",
      };
      if (this.$route.query.saveandapproval === "true") {
        this.historyDeal(obj);
      }
    },
    // 展示提交按钮
    showSubmitBtn(flag, approvalStatus) {
      if (flag) {
        let tempObj = {};
        if (
          approvalStatus.isApproval ||
          approvalStatus.isReassign ||
          approvalStatus.isRecall
        ) {
          if (approvalStatus.isApproval) {
            tempObj.showApproval = true;
          }
          if (approvalStatus.isReassign) {
            tempObj.showReassign = true;
          }
          if (approvalStatus.isRecall) {
            tempObj.showRecall = true;
          }
        } else {
          tempObj.showSubmit = true;
        }

        this.showHeaderHistoryBtn(tempObj);
      } else {
        let tempObj = {};
        if (approvalStatus.isApproval) {
          tempObj.showApproval = true;
        }
        if (approvalStatus.isReassign) {
          tempObj.showReassign = true;
        }
        if (approvalStatus.isRecall) {
          tempObj.showRecall = true;
        }

        this.showHeaderHistoryBtn(tempObj);
      }
    },
    showHeaderHistoryBtn(data) {
      this.showHistoryBtn = [];
      this.hideHistoryBtn = [];
      let btnArr = [];
      if (data.showSubmit) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.submitforapproval"),
          hisId: "0",
        }); //"提交待审批"
      }
      if (data.showApproval) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label_approveorrefuse"),
          hisId: "2",
        }); //进行审批
      }
      if (data.showReassign) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.redistribution"),
          hisId: "3",
        }); //重新分配
      }
      if (data.showRecall) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label_manageAll_sta4"),
          hisId: "1",
        }); //调回批准请求
      }
      let hasShenpi = false;
      btnArr.forEach((item) => {
        if (item.hisId === "2") {
          hasShenpi = true;
        }
      });
      if (btnArr.length < 2) {
        this.showHistoryBtn = btnArr;
        this.hideHistoryBtn = [];
      } else {
        if (hasShenpi) {
          btnArr.forEach((item) => {
            if (item.hisId === "2") {
              this.showHistoryBtn.push(item);
            } else {
              this.hideHistoryBtn.push(item);
            }
          });
        } else {
          this.showHistoryBtn = btnArr.slice(0, 1);
          this.hideHistoryBtn = btnArr.splice(1);
        }
      }
    },
    // 审批历史右上角展示的按钮
    getHistoryBtn(arr) {
      this.showHistoryBtn = [];
      this.hideHistoryBtn = [];
      let hasShenpi = false;
      arr.forEach((item) => {
        if (item.hisId === "2") {
          hasShenpi = true;
        }
        // if (item.hisId === "0") {
        //   this.historyDeal(item);
        // }
      });
      if (arr.length < 2) {
        this.showHistoryBtn = arr;
        this.hideHistoryBtn = [];
      } else {
        if (hasShenpi) {
          arr.forEach((item) => {
            if (item.hisId === "2") {
              this.showHistoryBtn.push(item);
            } else {
              this.hideHistoryBtn.push(item);
            }
          });
        } else {
          this.showHistoryBtn = arr.slice(0, 1);
          this.hideHistoryBtn = arr.splice(1);
        }
        // this.showHistoryBtn = arr.slice(0, 1);
        // this.hideHistoryBtn = arr.splice(1);
      }
    },
    historyDeal(item) {
      // this.$message(item.label)
      this.$refs.uponDetailHistory.parentClickHistory(
        item.hisId,
        this.detailGroupId,
        this.id
      );
    },
    // 新增/修改保存
    save() {
      //如果是保存刷新当前页面也用，跳转不需要
      // if (this.copyType === 'Clone') {
      //   this.$message.success(this.$i18n.t('label.search.saveok'));
      // } else {
      //   this.getBrief();
      //   this.$refs.listChild.init();
      // }
    },
    //打印
    printDetail() {
      // window.print()
      // localStorage.getItem('openWater') == 'true'
      window.open(
        `#/print-activeTable/${this.$route.query.tagId}/${this.$route.query.id}`,
        "_parent - URL",
        "height=700, width=1300, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
    },
    closeDialog() {
      this.printVisible = false;
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
    //获取价格手册数据
    getPricebook(n) {
      this.pricebookArr = n;
    },
    previewPdf() {
      let token = this.$CCDK.CCToken.getToken();
      this.pdfViewerAttr.pdfUrl = `api/quote/previewPdf?accessToken=${token}&quoteId=${this.id}`;
      this.pdfViewerAttr.isShow = true;
    },
    // 保存到文件
    saveToAppendix() {
      SavePdfToFile({
        quoteId: this.id,
      }).then((res) => {
        // 保存成功
        if (res.result) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label.search.saveok"),
          });
        } else {
          // 保存失败
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("savefail"),
          });
        }
        this.pdfViewerAttr.isShow = false;
      });
    },
    closeViewerDialog() {
      this.pdfViewerAttr.isShow = false;
    },
    // 确认
    confirm() {
      this.dialogVisible = false;
      if (
        this.dialogAttr.title ===
        this.$i18n.t("component_setup_tabs_label_delete")
      ) {
        const params = {
          id: this.$route.params.id,
          objectApi: this.objectApi,
        };
        GetDelete(params).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            });
            this.$router.go(-1);
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      } else {
        // 解除锁定
        CommonObjApi.recordUnLocked({ id: this.$route.params.id }).then(
          () => {
            // 解除锁定成功
            this.$message.success(
              this.$i18n.t("vue_label_commonobjects_detail_unlocksuccess")
            );
            this.refreshAll();
          }
        );
      }
    },
    // this.bigTitle[0].fieldValue
    getScroll(msg) {
      this.$refs.home.scrollTop = msg;
    },
    guanbis() {
      if (this.isHeight === false) {
        this.height = "60px";
        this.isHeight = true;
        this.icons = "el-icon-d-arrow-left";
      } else {
        this.height = "100%";
        this.isHeight = false;
        this.icons = "el-icon-d-arrow-right";
      }
    },
    routers() {
      if (this.objectApi === "Account") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Account",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "Contact") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Contact",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "campaign") {
        // this.isCustomer = true
        // this.content = '市场层级结构'
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Campaign",
            content: this.objectApi,
          },
        });
      } else {
        this.isCustomer = false;
      }
    },
    objectIcon() {
      // 市场活动
      // if (this.tabName === this.$i18n.t('label.marketingactivity')) {
      //   this.ROIflag = true
      // }
    },
    async Getrules() {
      let params = {
        id: this.$router.history.current.params.id,
      };
      let res = await GetSharegetShareRuleList(params);
      this.shares = res.data;
    },
    shuaxin() {
      this.$refs.listChild.getFormAttr();
    },
    convert(val) {
      this.isconvert = val;
    },
    async getObjectGetObjectInfos(val) {
      let params = {
        id: val,
      };
      let res = await getObjectGetObjectInfo(params);
      if (res.result) {
        this.isTag = res.data.istag;
      }
    },
    dataListes(val) {
      this.activeList = val.dataList;
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloses() {
      this.isPartnerCloud = false;
    },
    //详情简介跳转详情
    briefDetail(row) {
      if (row.fieldName === "ownerid") {
        // 所有人非队列可跳转
        if (row.fieldValueId && row.fieldValueId.slice(0, 3) === "005") {
          this.$router.push({
            path: `/commonObjects/detail/${row.fieldValueId}/DETAIL`,
            query: {
              viewId: "",
            },
          });
        } else {
          // 所有人队列不可跳转
          return false;
        }
      } else {
        CommonObjApi.getPermissionById({
          id: row.fieldValueId,
        }).then((res) => {
          if (res.data.isQuery === true) {
            this.$router.push({
              path: `/commonObjects/detail/${row.fieldValueId}/DETAIL`,
              query: {
                viewId: "",
              },
            });
          } else {
            this.$message.warning(
              this.$i18n.t("vue_label_notice_nopermission_view")
              //"抱歉，您无权查看该条数据，请联系系统管理员。"
            );
          }
        });
      }
    },
    async getPartnerGetPartnerPermissions(val) {
      let params = {
        id: this.id,
      };
      let res = await getPartnerGetPartnerPermission(params);
      if (res.result) {
        this.ismanagePartnerUser = res.data.managePartnerUser;
        this.ispartner = res.data.ispartner;
        this.userId = res.data.userId;
        if (this.ismanagePartnerUser) {
          let obj = {
            label: this.$i18n.t("label_query_detail_manageexuser"),
            name: "manageExternalUsers",
            //管理外部用户
          };
          this.moreButton.push(obj);
          this.unique(this.moreButton);
        }
        if (res.data.ispartner) {
          if (val === "Contact") {
            let buttons = [
              {
                label: this.$i18n.t("label_query_detail_viewpartneruser"),
                name: "viewPartners",
                // 查看合作伙伴用户
              },
              {
                label: this.$i18n.t("label_query_detail_editpartneruser"),
                name: "editPartner",
                // 编辑合作伙伴用户
              },
              {
                label: this.$i18n.t("label.query.detail.forbidpartneruser"),
                name: "disablePartner",
                // 禁用合作伙伴用户
              },
              {
                label: this.$i18n.t(
                  "vue_label_commonobjects_detail_cloud_as_a_user"
                ), //"以用户身份登陆伙伴云",
                name: "userPartner",
                // 以用户身份登陆伙伴云
              },
            ];
            this.partner = buttons;
          } else {
            this.partner = [
              {
                // 禁用合作伙伴
                label: this.$i18n.t("label.query.detail.closepartner"),
                name: "disablePartner",
              },
            ];
          }
        } else {
          if (val === "Account") {
            this.partner = [
              {
                // 启用合作伙伴用户
                label: this.$i18n.t("label_query_detail_openpartner"),
                name: "enablePartne",
              },
            ];
          } else {
            this.partner = [
              {
                // 启用合作伙伴用户
                label: this.$i18n.t("label.query.detail.openpartneruser"),
                name: "enablePartne",
              },
            ];
          }
        }
      }
    },
    external(val) {
      if (val.name === "enablePartne") {
        if (this.objectApi === "Account") {
          this.$confirm(
            // " 一旦将客户作为合作伙伴启用，它就会出现在isPartner字段上筛选过的报表和列表视图中。另外，合作伙伴用户可以从与此客户关联的联系人进行创建。是否确定？",
            this.$i18n.t("label_query_detail_openpartnermessage"),
            // "提示",
            this.$i18n.t("label_ccchat_message_new_error4"),
            {
              // 确定
              confirmButtonText: this.$i18n.t("label_ems_confirm"),
              // 取消
              cancelButtonText: this.$i18n.t("button_partner_cancel"),
              type: "warning",
            }
          ).then(() => {
            this.getPartnerPartnerEnableAccounts();
            this.freshen();
          });
        } else {
          this.getPartnerPartnerEnableContacts();
          this.freshen();
        }
      } else if (val.name === "disablePartner") {
        this.$confirm(
          this.$i18n.t("vue_label_commonobjects_detail_customers"),
          //"永久禁用外部用户的客户将禁止所有与客户关联的外部用户，同时从与他们关联的所有社区、组、小组、权限集和共享规则中删除。同时，将永久删除与该账户关联的角色和组，同时不会出现在回收站中。是否确定？",
          // "提示",
          this.$i18n.t("label_ccchat_message_new_error4"),
          {
            // 确定
            confirmButtonText: this.$i18n.t("label_ems_confirm"),
            // 取消
            cancelButtonText: this.$i18n.t("button_partner_cancel"),
            type: "warning",
          }
        ).then(() => {
          if (this.objectApi === "Account") {
            this.getPartnerPartnerEnableAccounts();
            this.freshen();
          } else if (this.objectApi === "Contact") {
            this.getPartnerPartnerEnableContacts();
            this.freshen();
          }
        });
      } else if (val.name === "viewPartners") {
        this.$router.push({
          path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
        });
      } else if (val.name === "editPartner") {
        this.$router.push({
          path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
          query: {
            edit: false,
          },
        });
      } else if (val.name === "userPartner") {
        this.getPartnerQueryAllPartnerByLogins();
      }
    },
    //客户下启用禁用合作伙伴
    async getPartnerPartnerEnableAccounts() {
      let params = {
        id: this.id,
      };
       await getPartnerPartnerEnableAccount(params);
    },
    //联系人下启用禁用合作伙伴
    async getPartnerPartnerEnableContacts() {
      let params = {
        id: this.id,
      };
      let res = await getPartnerPartnerEnableContact(params);
      if (res.result) {
        if (res.data.isfirst !== null && res.data.isfirst !== false) {
          this.dataList = res.data;
          this.$nextTick(() => {
            this.$refs.createEditObj.objId = this.objId;
            this.$refs.createEditObj.contacts();
          });
        } else {
          // 启用成功
          this.$message.success(this.$i18n.t("label_group_isusing_success"));
          this.freshen();
        }
      }
    },
    //获取所有能登录的伙伴云链接
    async getPartnerQueryAllPartnerByLogins() {
      let params = {
        id: this.id,
      };
      let res = await getPartnerQueryAllPartnerByLogin(params);
      if (res.result) {
        this.tableData = res.data;
        this.isPartnerCloud = true;
      }
    },
    freshen() {
      this.getBrief();
      this.$refs.addlabel.tagApi(this.objId, this.objectApi);
      this.$refs.listChild.setRelationList();
      this.$refs.listChild.getFormAttr();
    },
    Refreshes(val) {
      if (val === "true") {
        this.freshen();
      }
    },
    //togantt
    toGannt(type) {
      this.$router.push({
        path: "/gantt",
        query: {
          proId: this.id,
          proName: this.fieldValue,
          isGantt: type,
        },
      });
    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].name == arr[j].name) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //回到顶部
    backtop() {
      document.getElementById("contentMainBox").scrollTop = 0;
    },
  },
  watch: {
    realObjId(val) {
      // 控制甘特图按钮显隐
      if (val && val === "cloudcc_project") {
        this.isShowGanttButton = true;
      } else {
        this.isShowGanttButton = false;
      }
    },
    screenWidth: {
      immediate: true,
      handler(newValue) {
        if (!this.timer) {
          this.screenWidth = newValue;
          this.meunsFilter();
          this.timer = true;
          let that = this;
          setTimeout(function () {
            that.timer = false;
          }, 200);
        }
      },
    },
    id(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getIsHasGroup();
      }
    },
    labelName() {},
    // 监听分屏视图数据变化
    dataId(nval) {
      this.id = nval;
      this.$refs.listChild.id = nval;
      this.init();
      this.$refs.listChild.init();
    },
    tabName(nval) {
      // 联系人
      if (nval === this.$i18n.t("label_tabpage_contactsz")) {
        let obj = {
          objectApi: "Contact",
        };
        this.resetObjectInfo(obj);
      }
      this.$nextTick(() => {
        this.objectIcon();
      });
    },
    objId(val) {
      if (val !== "" && val !== undefined) {
        this.getObjectGetObjectInfos(val);
      }
      if (val === "task" || val === "event") {
        this.activeName = "second";
      } else if (this.isShowType === false) {
        this.activeName = "second";
      } else {
        this.activeName = "first";
      }
    },
    objectApi(val) {
      if (val === "Task" || val === "Event") {
        this.activeName = "second";
      } else if (this.isShowType === false) {
        this.activeName = "second";
      } else {
        this.activeName = "first";
      }
    },
    activeName(val) {
      if (val === "first") {
        this.$refs.activitys.init();
      } else if (val === "second" && this.isShowType === true) {
        //详情页
        this.$refs.dynamicPublics.second();
      }
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    eventTap () {
      if (this.objectApi === "Event") {
        return this.$i18n.t("label_event");
      }
    },
    contentBoxHeight() {
      let boxHeight = window.innerHeight - 310;
      return boxHeight + "px";
    },
  },
};
</script>

<style scoped lang="scss">
.li {
  padding: 0px 10px !important;
}
.icon {
  display: inline-block;
}
.layoutSwitchBox {
  position: absolute;
  left: calc(66.7% - 40px);
  top: 3px;
  text-align: center;
  width: 16px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  z-index: 100;

  img {
    width: 66px;
  }
}

::v-deep .el-dialog.qunce-dialog {
  padding: 20px;
  border-radius: 8px;
  .el-dialog__header {
    border-bottom: 1px solid #dcdcdc;
    padding: 0 0 10px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 900;
    }
  }
  .el-dialog__body {
    padding: 0;
    padding-top: 17px;
  }
  .el-button {
    margin-top: 35px;
    padding: 10px 15px;
  }
  .cancel {
    margin-left: calc(50% - 70px);
  }
}
.top {
  /* display: none; */
  width: 100%;
  height: 100%;
  background: #f2f2f5;
  padding: 10px 0px 0px 10px !important;
}
.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}
.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
  color: #3e3e3c;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.moreButtons li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
  color: #3e3e3c;
}
.moreButtons li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
<style scoped lang="scss">
::v-deep .dynamic {
  padding: 0 10px;
  .Post {
    margin-right: 0;
    margin-top: 20px;
  }
}

::v-deep .dynamic-public .detail-box {
  // padding: 10px 0px 13px;
  padding: 5px 0px 5px 0;
}
.home {
  height: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
  height: calc(100% - 10px);
  // text-align: center;
  // background: #fff;
  border-radius: 3px;

  .noPermissionBox {
    // width: 100%;
    text-align: center;
    position: absolute;
    top: calc(50% - 136px);
    left: calc(50% - 176.5px);

    .noPermissionTip {
      margin: 10px 0;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .contentBox {
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 999;
    top: 104px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    // min-width: 1270px;
  }
  .contentBoxs {
    z-index: 999;
    top: 104px;
    // min-width: 1270px;
  }
}

::v-deep .el-card__body {
  padding: 0px;
}

.box-card {
  width: 100%;
  border: 1px solid rgba(221, 219, 218, 1);
  border-radius: 0 !important;
  ul {
    padding: 10px;
    box-sizing: border-box;

    li {
      font-size: 12px;
      line-height: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .label_span {
        width: 80px;
        display: inline-block;
        margin-right: 20px;
      }

      .color_s span {
        width: 20px;
        height: 20px;
        background: green;
        margin: 5px;
        display: inline-block;
      }
    }
  }

  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}

.box-card {
  width: 100%;
  border: 1px solid rgba(221, 219, 218, 1);
  ::v-deep .el-card__body {
    padding: 0;
  }
  border-radius: 0 !important;
  .introduce {
    width: 100%;
    overflow-x: hidden;
    height: auto;
    display: flex;
    .introduce_title {
      font-size: 12px;
      color: #3e3e3c;
      letter-spacing: 0;
      text-align: justify;
      line-height: 22px;
      min-width: 80px;
      display: inline-block;
      white-space: nowrap;
      img {
        margin-left: 1px;
        margin-top: -2px;
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }

    .introduce_cet {
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      text-align: justify;
      line-height: 18px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
    }

    .briefDetail_s {
      color: #006dcc;
      cursor: pointer;
    }

    .introduce_content {
      float: left;
      margin: 10px 0 10px 35px;
      // min-width: 20px;
    }
  }
}

.guanbi {
  font-size: 16px;
  position: relative;
  top: 15px;
  left: 93%;
  cursor: pointer;
  z-index: 1;
}
// .newDongTai {
//   ::v-deep.el-tabs__active-bar {
//     left: 10px;
//   }
// }

.left {
  width: calc(66.7% - 10px);
  margin: 10px 10px 10px 0;
  float: left;
  position: relative;
}

::v-deep .right {
  float: right;
  margin-top: 10px;
  width: 33.3%;
  position: relative;
}

::v-deep #activityAndDynamicPublics {
  .optiones {
    .el-tabs__item {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold;
      color: #080707;
      min-width: 70px;
      text-align: center;
      font-weight: bold;
    }

    .is-active {
      font-weight: bold;
    }

    .el-tabs__active-bar {
      background: #006dcc;
    }
  }
}

.box_top {
  background: #f3f2f2;
  border: 1px solid #dddbda;
  border-bottom: 1px solid rgba(221, 219, 218, 1);
  min-height: 75px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  .left {
    width: 7px;
    height: 12px;
    margin-left: 11px;
    margin-top: 17px;
    margin-right: 12px;
    float: left;
    cursor: pointer;
  }

  .box_header {
    float: left;
    width: 36px;
    height: 36px;
    margin-top: 10px;
  }

  .title {
    height: 36px;
    float: left;
    margin-top: 8px;
    margin-left: 8px;
    max-width: calc(100% - 510px);
    min-width: 25%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .title_top {
      height: 12px;
      font-size: 12px;
      color: #333333;
      line-height: 18px;
    }

    .title_main {
      font-size: 16px;
      font-weight: 600;
      color: #080707;
      /*margin-top: -3px;*/
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      height: 20px;
      line-height: 18px;
    }
  }

  .header_right {
    float: right;
    display: flex;
    padding-right: 10px;
    .dayin {
      width: 28px;
      height: 28px;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 3px;
      cursor: pointer;
      margin: 16px 0 0 10px;
      .icon {
        margin: 2px 0 0 5px;
        width: 16px;
      }
    }
    ::v-deep .el-button-group {
      margin-top: 16px;
      position: relative;
      .moreButton {
        margin-bottom: 0;
        li {
          height: 30px;
          line-height: 30px;

          &:hover {
            color: #006dcc;
          }
        }
      }
      ::v-deep .button {
        height: 30px;
        text-align: center;
        padding: 0 15px;
        color: #006dcc;
        border: 1px solid #dedcda;
        &:hover {
          background: #fff;
          border: 1px solid #dedcda;
        }
      }
    }
    .xiala {
      width: 28px;
      height: 28px;
      border: 1px solid #dedcda;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      display: inline-block;
      background: #fff;
      text-align: center;
      cursor: pointer;
      img {
        width: 16px;
      }
    }

    .daying {
      width: 28px;
      height: 28px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #ccc;
      position: relative;
      left: 24px;
    }
  }
  .el-tag {
    margin-right: 5px;
    height: 18px;
    padding: 0 4px;
    line-height: 18px;
    border: 0;
  }
  .label {
    margin-left: 40px;
    margin-bottom: 8px;
  }

  .tag {
    float: left;
  }

  .link {
    min-width: 48px;
    font-size: 12px;

    font-weight: 400;
    color: rgba(0, 109, 204, 1);
    position: relative;
  }
}

.clears {
  min-height: 68px;
  // margin-top: 64px;
}
::v-deep .el-card.is-always-shadow {
  box-shadow: none;
  border: 1px solid #dddbda;
}
::v-deep .partnercloud {
  .el-dialog__header {
    font-size: 14px;
    color: #080707;
  }
  .el-dialog__body {
    padding: 30px 36px 30px 33px;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px !important;
  background-color: #dedcda !important;
}
.backtop {
  width: 40px;
  position: fixed;
  bottom: 21px;
  right: 0;
  cursor: pointer;
}
</style>
<style>
.external {
  padding: 12px 0px 12px 0px !important;
}
</style>
<style lang="scss" scoped>
.content-main {
  position: relative;
  clear: both;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 100;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 14px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-table__fixed-body-wrapper {
  // top: 48px !important;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #666666;
  border-bottom: 1px solid #dedcda;
  font-size: 12px;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td .cell {
  padding-right: 20px;
}
::v-deep .el-table th .cell {
  font-size: 12px;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin-top: -4px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

// #table-panel .content-main .el-table {
//   tr th {
//     background: #006dcc;
//   }
// }

::v-deep .el-table td {
  font-size: 12px;
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
