import axios from '@/config/httpConfig'
// 从业务机会同步
export function GetQuoteSyncingFromOpportunity(data) {
    return axios.post('/quote/syncingFromOpportunity', data)
}

// 从业务机会同步
export function GetQuoteSyncingToOpportunity(data) {
    return axios.post('/quote/syncingToOpportunity', data)
}
